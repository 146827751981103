import { HistorySelect } from 'components/ExportHistory/styled';
import { tagRender } from 'components/common/TagRender';
import { useGet } from 'hooks';
import { allUserAccounts } from 'services/api';

const UserFilter = ({ onChange, userIds }) => {
  function selectUsers(values) {
    onChange(values || []);
  }

  const [{ res: allUsers }] = useGet({
    url: allUserAccounts,
    onMount: true,
  });

  const mappedUsers = allUsers?.map(item => ({
    label: item.fullname,
    value: item.userId,
  }));

  return (
    <HistorySelect
      mode={'multiple'}
      placeholder='Users'
      showSearch={true}
      options={mappedUsers}
      onChange={selectUsers}
      tagRender={tagRender}
      remoteSearchOnClick
      allowClear={true}
      value={(mappedUsers && userIds?.map(x => ({ value: x }))) || []}
    />
  );
};

export default UserFilter;
