import { Input } from 'components/common';
import UserFilter from './UserFilter';
import {
  FilterItem,
  FiltersContainer,
  FiltersRow,
  HistorySelect,
  Switch,
} from '../styled';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import DateFilter from './DateFilter';
import { EXPORT_TYPE, StatusButtonConfig } from '../ExportHistoryHelper';
import { tagRender } from 'components/common/TagRender';

const statusOptions = Object.keys(StatusButtonConfig).map(key => ({
  label: StatusButtonConfig[key].text,
  value: key,
}));

export default function ExportHistoryFilters({
  filters,
  user,
  updateFilters,
  fullWidth,
}) {
  const [searchFilter, setSearchFilter] = useState(filters.searchFilter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce(value => {
      updateFilters({ ...filters, searchFilter: value, pageNumber: 0 });
    }, 300),
    [filters, updateFilters]
  );

  useEffect(() => {
    if (!filters?.userIds?.length) {
      updateFilters({ ...filters, userIds: [user.userId], pageNumber: 0 });
    }
    //eslint-disable-next-line
  }, []);

  function updateSearchFilter(value) {
    setSearchFilter(value);
    handleInputChange(value);
  }

  const handleUserSelectionChange = values => {
    updateFilters({ ...filters, userIds: values, pageNumber: 0 });
  };

  function setDontShowExpired(value) {
    updateFilters({ ...filters, dontShowExpired: value, pageNumber: 0 });
  }

  function updateDateFilter(values) {
    updateFilters({
      ...filters,
      startDate: values[0],
      endDate: values[1],
      pageNumber: 0,
    });
  }

  function updateType(value) {
    updateFilters({ ...filters, type: value, pageNumber: 0 });
  }

  function updateStatus(value) {
    updateFilters({ ...filters, status: value, pageNumber: 0 });
  }

  if (fullWidth)
    return (
      <FiltersContainer>
        <FiltersRow>
          <FilterItem>
            <Input
              onChange={updateSearchFilter}
              placeholder='Search'
              value={searchFilter}
              allowClear
            />
          </FilterItem>
          {user.isUserManager && (
            <FilterItem>
              <UserFilter
                onChange={handleUserSelectionChange}
                userIds={filters.userIds || []}
              />
            </FilterItem>
          )}
          <FilterItem>
            <HistorySelect
              options={Object.values(EXPORT_TYPE)}
              placeholder={'Type'}
              mode={'multiple'}
              value={filters.type}
              allowClear={true}
              onChange={updateType}
              tagRender={tagRender}
              disableTooltip
            />
          </FilterItem>
          <FilterItem>
            <HistorySelect
              options={statusOptions}
              placeholder={'Status'}
              mode={'multiple'}
              value={filters.status}
              allowClear={true}
              onChange={updateStatus}
              tagRender={tagRender}
            />
          </FilterItem>
          <DateFilter
            onChange={updateDateFilter}
            initialDateRange={
              filters.startDate && filters.endDate
                ? [filters.startDate, filters.endDate]
                : []
            }
          />
        </FiltersRow>
        <FiltersRow>
          <FilterItem>
            <Switch
              checked={filters.dontShowExpired}
              onChange={setDontShowExpired}
              label={'Exclude expired exports'}
            />
          </FilterItem>
        </FiltersRow>
      </FiltersContainer>
    );

  return (
    <FiltersContainer>
      <FiltersRow>
        <FilterItem>
          <Input
            onChange={updateSearchFilter}
            placeholder='Search'
            value={searchFilter}
            allowClear
          />
        </FilterItem>
        <DateFilter
          onChange={updateDateFilter}
          initialDateRange={
            filters.startDate && filters.endDate
              ? [filters.startDate, filters.endDate]
              : []
          }
        />
        <FilterItem>
          <Switch
            checked={filters.dontShowExpired}
            onChange={setDontShowExpired}
            label={'Exclude expired exports'}
          />
        </FilterItem>
      </FiltersRow>
      <FiltersRow>
        {user.isUserManager && (
          <FilterItem>
            <UserFilter
              onChange={handleUserSelectionChange}
              userIds={filters.userIds || []}
            />
          </FilterItem>
        )}
        <FilterItem>
          <HistorySelect
            options={Object.values(EXPORT_TYPE)}
            placeholder={'Type'}
            mode={'multiple'}
            value={filters.type}
            allowClear={true}
            onChange={updateType}
            tagRender={tagRender}
            disableTooltip
          />
        </FilterItem>
        <FilterItem>
          <HistorySelect
            options={statusOptions}
            placeholder={'Status'}
            mode={'multiple'}
            value={filters.status}
            allowClear={true}
            onChange={updateStatus}
            tagRender={tagRender}
          />
        </FilterItem>
      </FiltersRow>
    </FiltersContainer>
  );
}
