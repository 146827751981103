import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Divider } from 'antd';

import { useAuth } from 'hooks';
import { Checkbox, Detail, Input, Loader } from 'components/common';

import Total from './Total';
import Footer from './Footer';
import StatsProgress from './StatsProgress';
import Alerts from './Alerts';
import useCheckExport from './useCheckExport';
import {
  allTrial,
  downloadType as downloadTypeConstants,
  excludeAcademic,
  excludeIndustry,
} from './constants';
import { handleFailedExportNotification } from 'components/Search/Results/Actions/notifications';
import {
  hasFullLicense,
  isAcademicFull,
  isFullTrial,
  isIndustryFull,
  noAcademicLicence,
  noIndustryLicence,
} from 'utils/helpers/license';
import PreviouslyExportedMessage from './PreviouslyExportedMessage';
import { useSelector } from 'react-redux';
import { colours } from 'utils/theme';

const Container = styled.div`
  width: 360px;
  .exclude-previous-checkbox {
    .ant-checkbox + span {
      padding-right: 0;
      padding-left: 4px;
      font-size: 11px;
    }
  }
`;

function Summary({
  transactionId,
  regenerateTransactionId,
  downloadType,
  onClose,
  getQueryBody,
  onExport,
  canUseCredits,
}) {
  const { user } = useAuth();
  const [checkExportRes, checkExportLoading, error] = useCheckExport(
    transactionId,
    downloadType,
    getQueryBody,
    user.disableExport,
    canUseCredits
  );

  const {
    totalEmails,
    previouslyExportedEmails,
    academicCreditsRequired,
    academicCreditsUsed,
    academicCreditsTotal,
    industryCreditsRequired,
    industryCreditsUsed,
    industryCreditsTotal,
    reachedAcademicCreditsLimit,
    isAboutToReachAcademicCreditsLimit,
    reachedIndustryCreditsLimit,
    isAboutToReachIndustryCreditsLimit,
    uncategorisedEmails,
    daysUntilReset,
    academicEmails,
    industryEmails,
    noEmailContacts,
  } = checkExportRes || {};

  const [fileName, setFileName] = useState(null);
  const [excludePreviouslyExported, setExcludePreviouslyExported] = useState(
    useSelector(
      state => state.userSettings.excludePreviouslyExportedEmailsByDefault
    )
  );

  const [
    showExcludePreviouslyExportedMessage,
    setShowExcludePreviouslyExportedMessage,
  ] = useState(false);

  useEffect(() => {
    if (error) {
      if (error.status === 412) {
        regenerateTransactionId();
      }

      handleFailedExportNotification(error.status);
      onClose();
    }
  }, [error, onClose, regenerateTransactionId]);

  const exportTimeoutId = useRef(null);
  const handleFileNameUpdate = useCallback(value => {
    setFileName(f => (/^[a-z0-9-_]*$/i.test(value) ? value : f));
  }, []);

  const handlePreviouslyExportedChange = value => {
    if (value && excludePreviouslyExported === null)
      setShowExcludePreviouslyExportedMessage(true);

    setExcludePreviouslyExported(value);
  };

  const handleExport = useCallback(() => {
    onClose();
    if (exportTimeoutId.current) {
      clearTimeout(exportTimeoutId.current);
    }

    exportTimeoutId.current = setTimeout(
      () =>
        onExport({
          downloadType,
          fileName,
          transactionId,
          excludePreviouslyExported:
            excludePreviouslyExported === null
              ? false
              : excludePreviouslyExported,
          excludeAcademic: !isAcademicFull(user) || reachedAcademicCreditsLimit,
          excludeIndustry: !isIndustryFull(user) || reachedIndustryCreditsLimit,
        }),
      100
    );

    return () => {
      if (exportTimeoutId.current) {
        clearTimeout(exportTimeoutId.current);
      }
    };
  }, [
    onClose,
    onExport,
    fileName,
    transactionId,
    user,
    reachedAcademicCreditsLimit,
    reachedIndustryCreditsLimit,
    excludePreviouslyExported,
    downloadType,
  ]);

  if (checkExportLoading || error) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  const displayTotal = excludePreviouslyExported
    ? totalEmails - previouslyExportedEmails
    : totalEmails;

  if (user.hideResearcherEmail || user.disableExport) {
    return (
      <Container>
        <Alerts disabledExport={user.disableExport} />
        {downloadType !== downloadTypeConstants.crmPush && (
          <Detail
            textTransform='none'
            label='Name your export file'
            className='export-name'
            style={{ marginTop: '15px' }}
            headerStyle={{
              color: colours.black,
              fontWeight: 'normal',
              opacity: '60%',
            }}
          >
            <Input
              onKeyDown={e => e.stopPropagation()}
              placeholder='Type the name of your export here'
              maxLength={50}
              disabled={isFullTrial(user) || user.disableExport}
              onChange={handleFileNameUpdate}
              value={fileName}
            />
          </Detail>
        )}
        <Divider style={{ margin: '15px 0' }} />
        <Footer
          onCancel={onClose}
          onExport={handleExport}
          disabled={
            isFullTrial(user) ||
            user.disableExport ||
            (displayTotal === 0 && noEmailContacts === 0)
          }
        />
      </Container>
    );
  }

  const isAcademicUnlimited = academicCreditsTotal === -1;
  const isIndustryUnlimited = industryCreditsTotal === -1;

  const creditsTotal = () => {
    if (isFullTrial(user)) {
      return (
        (isAcademicUnlimited ? 0 : academicCreditsRequired ?? 0) +
        (isIndustryUnlimited ? 0 : industryCreditsRequired ?? 0)
      );
    }

    return (
      (isAcademicFull(user) && user.showAcademicCredits
        ? isAcademicUnlimited
          ? 0
          : academicCreditsRequired ?? 0
        : 0) +
      (isIndustryFull(user) && user.showIndustryCredits
        ? isIndustryUnlimited
          ? 0
          : industryCreditsRequired ?? 0
        : 0)
    );
  };

  const isDisabled =
    !hasFullLicense(user) ||
    user.disableExport ||
    (isAcademicFull(user) &&
      reachedAcademicCreditsLimit &&
      isIndustryFull(user) &&
      reachedIndustryCreditsLimit) ||
    (isAcademicFull(user) &&
      !isIndustryFull(user) &&
      reachedAcademicCreditsLimit) ||
    (isIndustryFull(user) &&
      !isAcademicFull(user) &&
      reachedIndustryCreditsLimit);

  return (
    <Container>
      {canUseCredits ? (
        <div>
          <Total
            user={user}
            excludePreviouslyExported={excludePreviouslyExported}
            totalEmails={displayTotal}
            academicCreditsRequired={academicEmails}
            industryCreditsRequired={industryEmails}
            previouslyExportedEmails={previouslyExportedEmails}
            academicCreditsUsed={academicCreditsUsed}
            industryCreditsUsed={industryCreditsUsed}
            uncategorisedEmails={uncategorisedEmails}
            credits={creditsTotal()}
            showCredits={
              user.isOnCreditSystem &&
              ((!noAcademicLicence(user) && user.showAcademicCredits) ||
                (user.showIndustryCredits && !noIndustryLicence(user)))
            }
            disabled={isDisabled}
            isAcademicUnlimited={isAcademicUnlimited}
            isIndustryUnlimited={isIndustryUnlimited}
          />
          <div style={{ margin: '15px 0' }} />
          {user.isOnCreditSystem &&
            (user.showAcademicCredits || user.showIndustryCredits) && (
              <>
                <StatsProgress
                  showAcademicCredits={
                    user.showAcademicCredits && isAcademicFull(user)
                  }
                  academicCreditsUsed={academicCreditsUsed}
                  academicCreditsRequired={academicCreditsRequired}
                  academicCreditsTotal={academicCreditsTotal}
                  showIndustryCredits={
                    user.showIndustryCredits && isIndustryFull(user)
                  }
                  industryCreditsUsed={industryCreditsUsed}
                  industryCreditsRequired={industryCreditsRequired}
                  industryCreditsTotal={industryCreditsTotal}
                  renewDays={daysUntilReset}
                />
              </>
            )}
          <Alerts
            isOnCreditSystem={user.isOnCreditSystem}
            aboutToReachAcademicLimits={isAboutToReachAcademicCreditsLimit}
            aboutToReachIndustryLimits={isAboutToReachIndustryCreditsLimit}
            reachedAcademicLimits={reachedAcademicCreditsLimit}
            reachedIndustryLimits={reachedIndustryCreditsLimit}
            disabledExport={user.disableExport}
            showAcademicCredits={user.showAcademicCredits}
            showIndustryCredits={user.showIndustryCredits}
            noExportEmails={displayTotal === 0}
            noExportContacts={displayTotal === 0 && noEmailContacts === 0}
          />
          <Divider style={{ margin: '15px 0' }} />
          <Checkbox
            className='exclude-previous-checkbox'
            disabled={isDisabled}
            checked={excludePreviouslyExported}
            onChange={value => handlePreviouslyExportedChange(value)}
            labelText="Don't include emails that have already been exported in previous downloads"
          />
          {showExcludePreviouslyExportedMessage && (
            <PreviouslyExportedMessage
              setShowPrevouslyExportedMessage={
                setShowExcludePreviouslyExportedMessage
              }
            />
          )}
        </div>
      ) : (
        <>
          {isFullTrial(user) && (
            <Alert
              type='info'
              showIcon
              style={{ fontSize: '12px', marginBottom: '15px' }}
              message={allTrial}
            />
          )}
        </>
      )}
      {downloadType !== downloadTypeConstants.crmPush && (
        <Detail
          textTransform='none'
          label='Name your export file'
          className='export-name'
          style={{ marginTop: '15px' }}
          headerStyle={{
            color: colours.black,
            fontWeight: 'normal',
            opacity: '60%',
          }}
        >
          <Input
            onKeyDown={e => e.stopPropagation()}
            placeholder='Type the name of your export here'
            maxLength={50}
            disabled={
              isDisabled || (displayTotal === 0 && noEmailContacts === 0)
            }
            onChange={handleFileNameUpdate}
            value={fileName}
          />
        </Detail>
      )}
      <Divider style={{ margin: '15px 0' }} />
      <Footer
        onCancel={onClose}
        onExport={handleExport}
        disabled={isDisabled || (displayTotal === 0 && noEmailContacts === 0)}
        tooltip={
          isDisabled
            ? ''
            : isAcademicFull(user) && reachedAcademicCreditsLimit
            ? excludeAcademic(user.showAcademicCredits)
            : isIndustryFull(user) && reachedIndustryCreditsLimit
            ? excludeIndustry(user.showIndustryCredits)
            : ''
        }
      />
    </Container>
  );
}

Summary.propTypes = {
  transactionId: PropTypes.string,
  regenerateTransactionId: PropTypes.func,
  downloadType: PropTypes.oneOf(
    Object.keys(downloadTypeConstants).map(k => downloadTypeConstants[k])
  ),
  onClose: PropTypes.func.isRequired,
  getQueryBody: PropTypes.func,
  onExport: PropTypes.func.isRequired,
};

export default Summary;
